import React from "react";
import max from "date-fns/max";
import { graphql } from "gatsby";
import CategoryPage from "../components/CategoryPage/CategoryPage";
import Layout from "../components/layout";

export default class CategoryTemplate extends React.Component {
  render() {
    const { posts, category, categories } = this.props.data;
    const lastUpdate = max(
      posts.edges.map(post => new Date(post.node.dateModified))
    );

    const postsByCategory = posts.edges
      .reduce((a, c) => {
        if (c.node.subcategory) {
          const pos = a.findIndex(e => e.slug === c.node.subcategory.slug);
          if (pos === -1) {
            a.push({
              ...c.node.subcategory,
              posts: [c]
            });
          } else {
            a[pos].posts.push(c);
          }
        }
        return a;
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(e => ({ ...e, total: e.posts.length, posts: e.posts.slice(0, 3) }));

    return (
      <Layout>
        <CategoryPage
          category={category}
          categories={categories}
          posts={posts.edges}
          postsByCategory={postsByCategory}
          lastUpdate={lastUpdate}
        />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPostBySlug($slug: String!) {
    posts: allCrossCastPost(
      limit: 2000
      sort: { fields: [dateModified], order: DESC }
      filter: { category: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          topProduct {
            id
            title
            imageId
            price
            cta
            ctaLabel
          }
          slug
          title
          category {
            title
            slug
          }
          subcategory {
            title
            slug
          }
          coverId
          coverAlt
          coverTitle
          abstract
          dateModified
        }
      }
    }
    category: crossCastCategory(slug: { eq: $slug }) {
      title
      slug
      metaTitle
      abstract
      description
    }
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
